.promineo-multi-select-dropdown-box {
  width: 100%;
}

.promineo-multi-select-dropdown-box-grid .promineo-base-grid .dx-datagrid-header-panel {
  margin-bottom: 0px!important;
}

.promineo-multi-select-dropdown-box-grid .promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-headers {
  padding-top: 8px;
}