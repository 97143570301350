.promineo-numberbox .dx-texteditor-container .dx-texteditor-input-container .dx-texteditor-input {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -5px;
  border-color: var(--color-ilap-blue);
}

.promineo-numberbox .dx-texteditor-container .dx-texteditor-input-container .dx-placeholder {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -5px;
  border-color: var(--color-ilap-blue);
}

.promineo-numberbox{
  height: 24px;
  width: 188px;
}

.promineo-numberbox .dx-icon.dx-icon-clear {
  padding: 8px 8px 8px 20px;
}


.promineo-numberbox.dx-state-hover {
  border-color: var(--color-ilap-blue);
}