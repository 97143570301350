.promineo-checkbox.dx-checkbox-checked .dx-checkbox-icon {
  color: var(--color-text-gray);
  border: 2px solid var(--color-text-gray);
}

.promineo-checkbox .dx-checkbox-icon,
.promineo-checkbox.dx-checkbox.dx-state-hover .dx-checkbox-icon, 
.promineo-checkbox.dx-checkbox.dx-state-focused .dx-checkbox-icon {  
  border: 2px solid var(--color-text-gray);  
}

.promineo-checkbox .dx-checkbox-text {
  padding-left: 15px;
  font-size: 12px;
  font-weight: 500;
  visibility: visible;  
  font-family: Inter;
  color: var(--color-text-gray);
}

.promineo-checkbox.dx-state-hover {
  background-color: var(--color-gray2) !important;
}