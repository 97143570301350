.landing-button.dx-button {
  border-radius: 16px;
  font-family: "Poppins";
}

.landing-button.dx-button .dx-button-content {
  padding: 6px 24px;
}

.landing-button.dx-button .dx-button-content .dx-button-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
}

.landing-button.dx-button.dx-state-disabled {
  background-color: var(--color-gray) !important;
  color: var(--color-white) !important;
}

.landing-button.dx-button.dx-state-disabled .dx-button-text {
  opacity: 1 !important;
}

/* Sign in button */

.landing-button.signIn-button.dx-button {
  background-color: var(--color-ilap-green);
  color: var(--color-white);
}

.landing-button.signIn-button.dx-button:hover {
  background-color: var(--color-light-green);
  color: var(--color-dark-green);
}

/* Read more button */

.landing-button.readMore-button.dx-button {
  background-color: var(--color-glass);
  color: var(--color-white);
  border: 2px solid var(--color-white);
}

.landing-button.readMore-button.dx-button:hover {
  background-color: var(--color-glass2);
  color: var(--color-white);
  border: 2px solid var(--color-white);
}
