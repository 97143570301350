.iea-scrollview .dx-scrollbar-hoverable {
  background-color: var(--color-lighter-gray);
}

.iea-scrollview .dx-scrollable-scroll {
  padding: 0px;
}

.iea-scrollview .dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 2px;
}

.iea-scrollview .dx-scrollbar-vertical.dx-scrollbar-hoverable {
  width: 2px;
}

.iea-scrollview .dx-scrollable-scroll-content {
  background-color: var(--color-gray);
}