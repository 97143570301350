.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr td:not(.promineo-grid-option-column, .grid-row-delete-option .custom-control-padding) {
  padding-top: 13px;
  padding-bottom: 13px;
}

.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr td.promineo-grid-option-column {
  padding-top: 6px !important;
  padding-bottom: 0px !important;
  margin: 0;
}

.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr td.grid-row-delete-option {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
  margin: 0;
}

.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr td.custom-control-padding {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr td .col-text{
  padding-top: 5px;
}

.promineo-grid:not(.disable-hover) .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr:hover {
  background-color: var(--color-gray2);
}

.promineo-grid td.promineo-grid-option-column .promineo-option-dropdown {
  display: none;
}

.promineo-grid tr.dx-row.dx-data-row:hover .promineo-option-dropdown {
  display: block;
}

.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr:hover tr:nth-last-child(n) {
  border: none;
}

.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-headers .dx-header-row span.dx-header-filter {
  font-size: 8px;
  margin-left: 10px;
}

.promineo-grid .dx-datagrid .dx-datagrid-header-panel {
  border: none;
}

.dx-header-filter-menu.dx-overlay-wrapper .dx-widget{
  font-size: 12px;
}
