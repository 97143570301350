.promineo-textbox.dx-texteditor.dx-state-active.dx-editor-filled,
.promineo-textbox.dx-texteditor.dx-state-active.dx-editor-outlined,
.promineo-textbox.dx-texteditor.dx-state-active.dx-editor-underlined,
.promineo-textbox.dx-texteditor.dx-state-focused.dx-editor-filled,
.dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-texteditor.dx-state-hover.dx-editor-outlined,
.promineo-textbox.dx-texteditor.dx-state-focused.dx-editor-underlined {
  border-color: var(--color-ilap-blue);
}

.promineo-textbox.dx-texteditor.size-large .dx-texteditor-input {
  color: var(--color-darkBlue);
}

.promineo-textbox.size-large {
  border-radius: 8px !important;
}

.promineo-textbox.size-large .dx-texteditor-input-container input {
  height: 32px;
}

.promineo-textbox.size-large input.dx-texteditor-input {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 700;
  padding-top: 2px;
  padding-bottom: 2px;
}

.promineo-textbox:not(.size-large) input.dx-texteditor-input {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  border-radius: 4px !important;
  margin-top: -6px;
  line-height: 18px;
}

.promineo-textbox:not(.size-large) .dx-placeholder {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -6px;
} 

.promineo-textbox:not(.size-large) {
  height: 24px;
  width: 330px;
}