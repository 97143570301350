.promineo-multiline-text-edit {
  border: 1px solid var(--color-light-gray);
}

.promineo-multiline-text-edit.medium-round {
  border-radius: 4px !important;
}

.promineo-multiline-text-edit.large-round {
  border-radius: 8px !important;
}

.promineo-multiline-text-edit:not(.default-background) {
  background-color: var(--color-gray2) !important;
}