.promineo-button.dx-button {
  border-radius: 8px;
  font-family: "Poppins";
}

.promineo-button-blue-text
{
  color: var(--color-ilap-blue);
  background-color: transparent;
  border: none;
}

.promineo-button.dx-button .dx-button-content .dx-button-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.promineo-button.dx-button.dx-state-disabled {
  background-color: var(--color-gray) !important;
  color: var(--color-white) !important;
}

.promineo-button.dx-button.dx-state-disabled .dx-button-text {
  opacity: 1 !important;
}

/* primary button */

.promineo-button.primary-button.dx-button {
  background-color: var(--color-ilap-blue);
  color: var(--color-white);
}

.promineo-button.primary-button.dx-button:hover {
  background-color: var(--color-light-blue);
  color: var(--color-blue);
}

/* secondary button */

.promineo-button.secondary-button.dx-button {
  background-color: var(--color-background-gray);
  color: var(--color-ilap-blue);
  border: 1px solid var(--color-ilap-blue);
}

/* ternary button */

.promineo-button.ternary-button.dx-button {
  font-family: 'Poppins';
  color: var(--color-ilap-blue);
  font-size: 14px;
  line-height: 21px;
  border: none;
}

.promineo-button.ternary-button.dx-state-disabled {
  color: var(--color-light-text-gray) !important;
  background-color: var(--color-light-gray) !important;
}

.promineo-button.ternary-button.dx-state-hover {
  background-color: var(--color-lighter-blue) !important;
}

.promineo-button.ternary-button.dx-state-focused.dx-state-active {
  background-color: var(--color-blue-200) !important;
}

/* success button */

.promineo-button.success-button.dx-button {
  background-color: var(--color-ilap-green);
  color: var(--color-white);
}

.promineo-button.success-button.dx-button:hover {
  background-color: var(--color-light-green);
  color: var(--color-dark-green);
}

/* danger button */

.promineo-button.danger-button.dx-button {
  background-color: var(--color-light-red);
  color: var(--color);
}

/* basic button */
.promineo-button.basic-button.dx-button {
  background-color: var(--color-gray2);
  color: var(--color-text-gray);
  border: 1px solid var(--color-text-gray);
  border-radius: 4px;
}

/* Borderless button */
.promineo-button.borderless-button.dx-button {
  background-color: transparent;
  color: var(--color-text-gray);
  border: none;
}

.promineo-button.borderless-button.dx-button:hover {
  color: black;
}

/* BorderlessDanger button */
.promineo-button.borderless-danger-button.dx-button {
  background-color: transparent;
  color: var(--color-red);
  border: none;
}

.promineo-button.borderless-danger-button.dx-button:hover {
  color: var(--color-red);
}

/* icon color for button with icon (normal, hover & disabled state)*/
.promineo-button.dx-button .dx-button-content svg>path {
  fill: currentColor;
}

.promineo-button.dx-button:hover .dx-button-content svg>path {
  fill: currentColor;
}

.promineo-button.dx-button:hover .dx-button-content svg>rect {
  fill: currentColor;
}

.promineo-button.dx-button:hover .dx-button-content svg>polygon {
  fill: currentColor;
}

.promineo-button.dx-button.dx-state-disabled .dx-button-content svg>path {
  fill: currentColor;
}

.promineo-button.dx-button.dx-state-disabled .dx-button-content svg>rect {
  fill: currentColor;
}

.promineo-button.dx-button.dx-state-disabled .dx-button-content svg>polygon {
  fill: currentColor;
}