.promineo-button.promineo-button-with-icon .dx-button-content {
  padding: 0;
}

.promineo-button.promineo-button-with-icon .button-with-icon {
  padding: 5px 16px;
}

.promineo-button.promineo-button-with-icon .button-with-icon-text {
  font-weight: 600;
}