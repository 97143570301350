.promineo-tree-view {
  font-size: 10px;
  line-height: 15px;
  font-family: "Inter";
  font-weight: 500;
}

.promineo-tree-view .dx-treeview-node {
  padding-left: 8px;
}

.promineo-tree-view .dx-item.dx-treeview-item:hover {
  background-color: var(--color-gray2);
}

.promineo-tree-view .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item {
  background-color: transparent;
  color: black;
}

.promineo-tree-view .dx-treeview-item-without-checkbox.dx-state-selected>.dx-treeview-item {
  color: #333;
  background-color: var(--color-gray1);
}

.promineo-tree-view .dx-treeview-item-without-checkbox .dx-treeview-item {
  padding: 4px 10px;
  min-height: 25px;
}

.dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened {
  font: 12px/1 DXIcons;
}

.dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
  content: "\f016";
}

.dx-treeview-toggle-item-visibility {
  font: 12px/1 DXIcons;
}

.dx-treeview-toggle-item-visibility::before {
  content: "\f010";
}

/* Checkbox overrides */
.promineo-tree-view .dx-checkbox.dx-state-hover .dx-checkbox-icon {
  border: 1px solid black;
}

.promineo-tree-view .dx-checkbox.dx-checkbox-checked {
  border-color: black;
}

.promineo-tree-view .dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border: 1px solid black;
}

.promineo-tree-view .dx-treeview-item-with-checkbox.dx-state-focused>.dx-checkbox .dx-checkbox-icon {
  border: 1px solid black;
}

.promineo-tree-view .dx-checkbox-icon {
  width: 16px;
  height: 16px;
}

.promineo-tree-view .dx-checkbox-checked .dx-checkbox-icon {
  color: black;
  font-size: 10px;
}

.promineo-tree-view .dx-checkbox-indeterminate .dx-checkbox-icon {
  color: black;
  font-size: 8px;
}

/* End of Checkbox overrides */