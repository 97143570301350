.promineo-option-dropdown .dx-buttongroup .dx-button {
    border: none;
}

.promineo-option-dropdown .dx-buttongroup .dx-button .dx-button-content {
    padding: 4px 10px;
}

.promineo-option-dropdown .dx-buttongroup .dx-button .dx-button-content i {
    display: none;
}

.promineo-option-dropdown-popup .dx-dropdownbutton-content {
    margin: 8px 0px;
}

.promineo-option-dropdown-popup .dx-dropdownbutton-content .dx-list-item {
    padding: 0px 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--color-text-gray);
    display: block;
}

.promineo-option-dropdown-popup .dx-overlay-content {
    border-radius: 8px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.15)
}