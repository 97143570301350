.promineo-dropdown-box {
  height: 24px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.rounded-both {
  border-radius: 8px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.rounded-left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.rounded-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.round-small.rounded-both {
  border-radius: 4px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.round-small.rounded-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.round-small.rounded-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined.rounded-none {
  border-radius: 0px;
}

.promineo-dropdown-box.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  margin: 0;
  padding: 6px 8px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 8px;
  min-height: 24px;
  height: 24px;
}

.promineo-dropdown-box .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.promineo-dropdown-box.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
}

.promineo-dropdown-box .dx-texteditor-input-container .dx-placeholder {
  font-size: 10px;
  line-height: 12px;
}

.promineo-dropdown-box.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
}
.promineo-dropdown-box.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
  border-color: transparent;
}