.promineo-base-grid {
    font-family: 'Inter', sans-serif !important;
}

/* hide sort index (1, 2...) from grid header*/
.promineo-base-grid .dx-header-row .dx-column-indicators .dx-sort-index-icon {
    display: none;
}

.promineo-base-grid .dx-datagrid-header-panel {
    margin-bottom: 12px;
}

.promineo-base-grid .dx-datagrid-header-panel .dx-toolbar-after {
    margin-top: 13px;
}

.promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-headers .dx-datagrid-table.dx-datagrid-table-fixed tr td {
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
}

.promineo-base-grid .dx-datagrid.dx-gridbase-container {
    background-color: transparent;
}

.promineo-base-grid .dx-datagrid-header-panel .dx-toolbar {
    background-color: transparent;
}

.promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-headers,
.promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview {
    background-color: white;
}

.promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-headers {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-text-gray);
    border: 1px solid var(--color-light-gray);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 56px;
}

.dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content{
    white-space: normal;
}

.promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-text-gray);
    border: 1px solid var(--color-light-gray);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* There is invisible row in the last */
.promineo-base-grid tr.dx-row.dx-data-row:not(:nth-last-child(2)) {
    border-bottom: 1px solid var(--color-light-gray);
}

.dx-datagrid-focus-overlay {
    border: none;
}

.promineo-base-grid .dx-datagrid.dx-gridbase-container tr.dx-row.dx-data-row .add-left-border {
    border-left: 1px solid #7f7f7f !important;
}

.promineo-base-grid .delete-row-button {
    display: none;
}

.promineo-base-grid tr.dx-row.dx-data-row:hover .grid-row-delete-option .delete-row-button {
    display: block;
}

/* Search bar */


.promineo-base-grid .dx-datagrid .dx-datagrid-search-panel.dx-searchbox {
    border-radius: 8px;
    height: 32px;
}

.promineo-base-grid .dx-datagrid .dx-datagrid-search-panel {
    width: 200px !important;
    margin-left: 0px;
}

.promineo-base-grid .dx-datagrid .dx-datagrid-search-panel .dx-texteditor-input-container {
    align-items: center;
}

.promineo-base-grid .dx-datagrid .dx-datagrid-search-panel.dx-searchbox input {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.promineo-base-grid .dx-datagrid .dx-datagrid-search-panel.dx-searchbox {
    border-width: 2px;
    border-color: var(--color-light-gray);
}

.promineo-base-grid .dx-datagrid .dx-datagrid-search-panel.dx-searchbox .dx-placeholder {
    font-size: 10px;
    font-weight: 500;
    color: var(--color-text-gray);
    line-height: 12px;
}

.promineo-base-grid .dx-searchbox .dx-icon-search {
    font: 15px/15px DXIcons;
    margin-top: -15px;
    width: 24px;
    height: 24px;
    padding: 9px 5px 5px 8px;
}

.promineo-base-grid .dx-show-clear-button .dx-icon-clear {
    font-size: 14px;
}

.promineo-base-grid .dx-datagrid-nodata {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Grid checkbox */

.promineo-base-grid .dx-checkbox-checked .dx-checkbox-icon {
    color: var(--color-text-gray);
    border: 2px solid var(--color-text-gray);
}

.promineo-base-grid .dx-checkbox-icon,
.promineo-base-grid .dx-checkbox.dx-state-hover .dx-checkbox-icon,
.promineo-base-grid .dx-checkbox.dx-state-focused .dx-checkbox-icon {
    border: 2px solid var(--color-text-gray);
}

.promineo-base-grid .dx-checkbox-text {
    padding-left: 15px;
    font-size: 12px;
    font-weight: 500;
    visibility: visible;
    font-family: Inter;
    color: var(--color-text-gray);
}

.promineo-base-grid .dx-state-hover {
    background-color: var(--color-gray2) !important;
}

.promineo-base-grid .dx-checkbox-indeterminate .dx-checkbox-icon {
    color: var(--color-darkBlue);
}

/* End of grid checkbox */

/* Filter dropdown */
.dx-header-filter-menu .dx-popup-bottom .dx-toolbar-button .dx-toolbar-item-content .dx-button {
    height: 30px;
}

.dx-header-filter-menu .dx-popup-bottom.dx-toolbar {
    padding: 10px 20px 10px 20px;
}

.dx-header-filter-menu .dx-checkbox.dx-state-hover .dx-checkbox-icon {
    border: 1px solid black;
}

.dx-header-filter-menu .dx-checkbox.dx-checkbox-checked {
    border-color: black;
}

.dx-header-filter-menu .dx-checkbox.dx-state-focused .dx-checkbox-icon {
    border: 1px solid black;
}

.dx-header-filter-menu .dx-checkbox-icon {
    width: 17px;
    height: 17px;
}

.dx-header-filter-menu .dx-checkbox-checked .dx-checkbox-icon {
    color: black;
    font-size: 10px;
}

.dx-header-filter-menu .dx-checkbox.dx-checkbox-indeterminate .dx-checkbox-icon:before {
    background-color: var(--color-light-text-gray);
    font-size: 8px;
}

.dx-header-filter-menu .dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-hover .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-hover .dx-radiobutton-icon::before {
    border-color: black;
}

.dx-header-filter-menu .dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-focused .dx-radiobutton-icon::before {
    border: 1px solid black;
}

.dx-header-filter-menu .dx-list-select-all-label {
    margin-top: 0px;
}

/* End of Checkbox overrides */

/* No data display */
.promineo-base-grid .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-empty.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
    background-image: url("static/images/NoDataIcon.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.promineo-base-grid .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-empty .dx-datagrid-nodata {
    margin-top: 85px;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 15px;
    color: var(--color-light-text-gray);
    width: 240px;
    word-wrap: break-word;
    text-wrap: wrap;
    display: inline-block;
}

.promineo-base-grid .dx-datagrid-rowsview.dx-datagrid-nowrap:not(.dx-empty) .dx-datagrid-nodata {
    display: none;
}

/* End of no data */