.promineo-selectbox .dx-dropdowneditor-input-wrapper.dx-selectbox-container .dx-texteditor-input-container .dx-texteditor-input {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -5px;
  border-color: var(--color-ilap-blue);
}

.promineo-select-dropdown-popup .dx-popup-content .dx-scrollview-content {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
}

.promineo-selectbox .dx-icon.dx-icon-clear {
  padding: 8px 8px 8px 20px;
}

.promineo-selectbox .dx-dropdowneditor-input-wrapper.dx-selectbox-container .dx-texteditor-input-container .dx-placeholder {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -3px;
  border-color: var(--color-ilap-blue);
}

.promineo-selectbox {
  height: 24px;
}

.promineo-selectbox.dx-state-hover {
  border-color: var(--color-ilap-blue);
}
