.promineo-dropdown-button {
  height: 32px;
}

.promineo-dropdown-button.dx-button-mode-contained.dx-buttongroup-last-item.dx-button,
.dx-button-mode-contained.dx-buttongroup-last-item.dx-state-hover .dx-button-content,
.dx-button-mode-outlined.dx-buttongroup-last-item.dx-button,
.dx-button-mode-outlined.dx-buttongroup-last-item.dx-state-hover .dx-button-content {
  border-radius: 8px;
}

.promineo-dropdown-button .dx-dropdownbutton-action.dx-button .dx-button-content {
  padding-left: 16px;
  padding-right: 12px;
}

.promineo-dropdown-button .dx-button-mode-outlined {
  border-color: var(--color-blue-200);
  background-color: white;
  color: var(--color-ilap-blue)
}

.promineo-dropdown-button .dx-button-mode-outlined:hover {
  background-color: var(--color-blue-100) !important;
}

.promineo-dropdown-button .dx-button-mode-outlined.dx-state-focused {
  background-color: white !important;
}

.promineo-dropdown-button .dx-button-mode-outlined.dx-state-focused.dx-state-hover {
  background-color: var(--color-blue-200) !important;
}

.promineo-dropdown-button .dx-icon-spindown::before {
  content: "\f016";
}

.promineo-dropdown-button .dx-icon {
  color: var(--color-ilap-blue)
}

.promineo-dropdown-button .dx-button-has-text .dx-icon {
  font-weight: bold;
  font-size: 12px;
}

.dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  padding: 6px 9px;
}

.dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: #fff !important;
  color: black;
}

.dx-dropdownbutton-popup-wrapper.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 8px;
}