.promineo-popup .dx-overlay-content.dx-popup-normal {
  border-radius: 8px !important;
}

.promineo-popup
  .dx-overlay-content.dx-popup-normal.dx-resizable
  .dx-popup-content {
  padding: 0px !important;
}

.promineo-popup.colored-bg .dx-overlay-content {
  background: var(--color-white);
}
