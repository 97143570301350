.dx-datebox-wrapper-calendar .dx-calendar {
  margin: 16px 1px !important;
}

.dx-calendar {
  width: 250px;
  min-width: 200px;
  height: 250px;
  min-height: 200px;
}

.promineo-datepicker .dx-textbox .dx-texteditor-input-container {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -5px;
}

.promineo-datepicker .dx-textbox .dx-texteditor-input-container .dx-placeholder {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: 2px;
}

.promineo-datepicker .dx-texteditor-buttons-container .dx-button-content {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: 7px;
  margin-left: 15px;
}

.dx-calendar-navigator .dx-button-has-text .dx-button-content {
  padding: 0px !important;
}

.dx-calendar-navigator .dx-button {
  border-radius: 4px !important;
  border: 1px solid var(---color-light-gray);
}

.dx-calendar-navigator-previous-month.dx-button .dx-icon, .dx-calendar-navigator-previous-view.dx-button .dx-icon {
  color: black;
}

.dx-calendar-navigator-next-month.dx-button .dx-icon, .dx-calendar-navigator-next-view.dx-button .dx-icon {
  color: black;
}

.dx-calendar-navigator .dx-calendar-caption-button, .dx-calendar-navigator .dx-button.dx-state-hover{
  border: none;
}

.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date, .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
  -webkit-box-shadow: inset 0 0 0 0, inset 0 0 0 1000px var(--color-ilap-blue) !important;
  box-shadow: inset 0 0 0 0, inset 0 0 0 1000px var(--color-ilap-blue) !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  font-weight: 500;
}

.dx-calendar-cell.dx-state-hover {
  -webkit-box-shadow: inset 0 -1px 0 1000px var(--color-light-blue) !important;
  box-shadow: inset 0 -1px 0 1000px var(--color-light-blue) !important;
}

.dx-calendar-cell.dx-calendar-today {
  color: var(--color-ilap-blue);
}

.dx-datebox-wrapper-calendar .dx-toolbar.dx-widget{
  padding: 0px 5px;
}

.dx-datebox-wrapper-datetime.dx-datebox-wrapper-calendar .dx-toolbar-items-container .dx-item.dx-toolbar-item.dx-toolbar-button .dx-button{
  height: 25px;
}