:root {
  --color-blue: #304691;
  --color-blue-50: #A4B9FF;
  --color-blue-200: #C1CEFC;
  --color-blue-100: #D9E1FF;
  --color-blue-600: #1839AD;
  --color-darkBlue: #000d39;
  --color-gray: #ababab;
  --color-gray1: #e6e6e6;
  --color-gray2: #f9f9f9;
  --color-light-text-gray: #727272;
  --color-light-gray: #d7d7d7;
  --color-lighter-gray: #EAEAEA;
  --color-background-gray: #f2f3f6;
  --color-ilap-blue: #3459db;
  --color-lighter-blue: #d9e1ff;
  --color-ilap-green: #53ae96;
  --color-light-green: #aad8cb;
  --color-dark-green: #16644f;
  --color-light-red: #ff9090;
  --color-green-500: #009C7C;
  --color-light-blue: #95ACFF;
  --color-white: #ffffff;
  --color-text-gray: #434343;
  --color-red: #ff3a3a;
  --color-orange: #ff9a3a;
  --color-light-orange: #ffd18b;
  --color-purple: #bd9de7;
  --color-ilap-light-blue: #95acff;
  --color-dark-purple: #54426c;
  --color-lighter-orange: #ffeaca;
  --color-lighter-red: #ffdcdc;
  --color-glass: #ffffff19;
  --color-glass2: #ffffff55;
  --color-gray3: #3B3F4E;
  --color-frame-grey: #EEEEEE;
}