.promineo-rounded-tabpanel .dx-multiview-wrapper, .promineo-rounded-tabpanel .dx-tabs-wrapper .dx-item.dx-tab {
  background-color: white;
}

.promineo-rounded-tabpanel .dx-tabs .dx-item.dx-tab {
  border: none;
}

.promineo-rounded-tabpanel.promineo-tab-panel.dx-tabpanel .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
  box-shadow: none;
}

.promineo-rounded-tabpanel.promineo-tab-panel.dx-tabpanel .dx-tabs {
  border-bottom: none;
}

.promineo-rounded-tabpanel.promineo-tab-panel.dx-tabpanel .dx-tabpanel-tabs .dx-item.dx-tab {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #EEEEEE;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.promineo-rounded-tabpanel.promineo-tab-panel.dx-tabpanel .dx-tabpanel-tabs .dx-item.dx-tab.dx-tab-selected {
  border-bottom: 1px solid white;
}

.promineo-rounded-tabpanel.promineo-tab-panel .dx-item.dx-tab{
  padding: 10px 16px;
}