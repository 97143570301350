.dx-widget {
  font-family: 'Inter';
}

.dx-popup-content {
  padding: 0;
}

.dx-overlay-content {
  @apply rounded;
}

.promineo-grid-in-modal.promineo-base-grid tr.dx-row.dx-data-row:not(:nth-last-child(2)) {
  border-color: white;
}

.promineo-grid-in-modal.promineo-base-grid tr {
  background-color: var(--color-gray2);
}

/* Scrollbar override */

.promineo-base-grid .dx-scrollbar-vertical.dx-scrollbar-hoverable {
  width: 15px;
}

.promineo-base-grid .dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 15px;
}

.promineo-base-grid .dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  height: 15px;
}

.promineo-base-grid .dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 15px;
}

/* End of Scrollbar override */