.copy-button {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.copy-icon {
  fill: var(--color-gray-9);
}

.copy-button:hover {
  background-color: var(--color-lighter-gray);
}

.copy-button:active {
  background-color: var(--color-light-gray);
}