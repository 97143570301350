.promineo-list .dx-list-search {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: var(--color-light-text-gray);
  margin-bottom: 1px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid var(--color-light-gray);
}

.promineo-list .dx-texteditor.dx-state-hover {
  border-color: transparent;
  border-bottom-color: var(--color-light-gray);
}

.promineo-list .dx-texteditor.dx-state-focused {
  border-color: transparent;
  border-bottom-color: var(--color-light-gray);
}

.promineo-list .dx-scrollview-content .dx-item.dx-list-item {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
}

.promineo-list.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: var(--color-gray2);
  color: black;
}