.promineo-edit-grid .dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  vertical-align: middle;
}

.promineo-edit-grid .dx-revert-button {
  display: none;
}

.promineo-edit-grid .dx-data-row tr td {
  font-size: 12px;
  font-weight: 500 !important;
  font-family: Inter;
  line-height: 16px;
}

.promineo-edit-grid.view-only .dx-row td {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.promineo-edit-grid .dx-row.dx-data-row {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

.promineo-edit-grid .dx-editor-cell div.dx-texteditor {
  background: transparent;
}

.promineo-edit-grid td.dx-editor-cell .dx-texteditor-input-container {
  padding: 8px;
  line-height: 12px;
}

.promineo-edit-grid .dx-row.dx-data-row td.dx-editor-cell input {
  border: 1px solid var(--color-light-gray);
  height: 24px;
  min-height: 24px;
  padding: 6px 8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

.promineo-edit-grid .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after {
  border: none;
}