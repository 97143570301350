.promineo-accordion.dx-accordion {
  background-color: transparent;
}

.promineo-accordion .dx-accordion-item {
  border: none;
}

.promineo-accordion.dx-accordion .dx-item.dx-accordion-item.dx-item-selected.dx-accordion-item-opened {
  border: none !important;
}

.promineo-accordion .dx-item-content.dx-accordion-item-title {
  display: flex;
  column-gap: 8px;
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins;
}

.promineo-accordion .dx-item-content.dx-accordion-item-title::before {
  font-size: 14px;
}

.promineo-accordion .dx-item-content.dx-accordion-item-title>div:nth-child(1) {
  margin-top: 2px;
}

.promineo-accordion .dx-state-focused.dx-accordion-item {
  border: none;
}

.promineo-accordion .dx-accordion-item.dx-state-focused.dx-accordion-item-closed.dx-state-hover {
  background-color: transparent;
}

.promineo-accordion .dx-accordion-item.dx-state-active:not(.dx-accordion-item-opened)>.dx-accordion-item-title {
  background-color: transparent;
}

.promineo-accordion .dx-accordion-item.dx-state-focused.dx-state-hover {
  background-color: transparent;
}

.promineo-accordion .dx-accordion-item.dx-state-focused.dx-state-hover>.dx-accordion-item-title {
  background-color: transparent;
}


/* Confirmation dialog accordion */

.promineo-confirmation-dialog-accordion .dx-accordion-item-title {
  padding: 4px 0px;
}

.promineo-confirmation-dialog-accordion .dx-accordion-item-title-caption {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.promineo-confirmation-dialog-accordion .dx-accordion-item-title::before {
  margin: 0px;
  rotate: -90deg;
}

.promineo-confirmation-dialog-accordion .dx-accordion-item-opened>.dx-accordion-item-title::before {
  rotate: 180deg;
}

/* End of confirmation dialog accordion */