.dx-tooltip-wrapper .dx-overlay-content {
  background-color: var(--color-darkBlue);
  border-color: var(--color-darkBlue);
  color: white;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  border-radius: 4px;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  margin-top: 1px;
  padding-left: 16px;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 0;
  white-space: initial;
  text-align: left;
}

.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow::after {
  background-color: var(--color-darkBlue);
  border-color: var(--color-darkBlue);
}

.dx-popover-wrapper .dx-popover-arrow {
  inset: auto auto -10px 15px !important;
}

.dx-popover-wrapper .dx-popover-arrow::after {
  width: 8px;
  height: 8px;
}

.dx-popover-wrapper.dx-position-bottom .dx-popover-arrow,
.dx-popover-wrapper.dx-position-top .dx-popover-arrow {
  width: 11px;
  height: 10px;
}