.promineo-multi-select-popover-grid.promineo-base-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-left: none;
  border-right: none;
}

.promineo-multi-select-popover-grid.promineo-base-grid.promineo-grid .dx-datagrid.dx-gridbase-container .dx-datagrid-rowsview tr td:not(.promineo-grid-option-column, .grid-row-delete-option .custom-control-padding) {
  padding-top: 10px !important;
  padding-bottom: 7px !important;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

/* There is invisible row in the last */
.promineo-multi-select-popover-grid.promineo-base-grid tr.dx-row.dx-data-row:not(:nth-last-child(2)) {
  border-bottom: none;
}

.promineo-multi-select-popover-grid{
  margin-top: -12px;
  max-height: 230px;
}

.promineo-multi-select-popover-grid.promineo-base-grid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  padding-left: 10px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}

.promineo-multi-select-popover-grid.promineo-base-grid .dx-checkbox-checked .dx-checkbox-icon {
  color: var(--color-white);
  background-color: var(--color-ilap-blue);
  border: none;
}


.promineo-multi-selection-popover .dx-popup-content{
  border-radius: 20px !important;
}

.promineo-multi-selection-popover-textbox.promineo-textbox.size-large{
  border-radius: 0px !important;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
}

.promineo-multi-selection-popover-textbox.promineo-textbox.size-large input.dx-texteditor-input{
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 16px;
}