.promineo-tab-panel.dx-tabpanel.dx-state-focused .dx-multiview-wrapper {
  border: none;
}

.promineo-tab-panel.tabs-hidden .dx-tabpanel-tabs {
  display: none;
}

.promineo-tab-panel.dx-tabpanel .dx-tabs-expanded {
  background-color: transparent;
}

.promineo-tab-panel.dx-tabpanel .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
  -webkit-box-shadow: inset 0 -2px 1px -1px black;
  box-shadow: inset 0 -2px 1px -1px var(--color-ilap-blue);
}

.promineo-tab-panel.dx-tabpanel .dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-item-content.dx-tab-content .dx-tab-text {
  color: var(--color-ilap-blue);
}

.promineo-tab-panel.dx-tabpanel .dx-tabpanel-tabs .dx-tab.dx-state-hover .dx-tab-text {
  color: var(--color-ilap-blue);
}

.promineo-tab-panel.dx-tabpanel .dx-tabs {
  border-bottom: 1px solid var(--color-gray);
}

.promineo-tab-panel.dx-tabpanel .dx-tabs .dx-tab {
  background-color: transparent;
}

.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tab {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-expanded .dx-tab:not(.dx-tab-selected):first-of-type {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-rtl .dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-expanded .dx-tab:not(.dx-tab-selected):first-of-type,
.dx-rtl.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-expanded .dx-tab:not(.dx-tab-selected):first-of-type,
.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-stretched .dx-tab:not(.dx-tab-selected):last-of-type {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-rtl .dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-stretched .dx-tab:not(.dx-tab-selected):last-of-type,
.dx-rtl.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-stretched .dx-tab:not(.dx-tab-selected):last-of-type,
.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-nav-button-left {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-tabpanel.dx-state-focused>.dx-tabpanel-tabs .dx-tabs-nav-button-right {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-tabpanel>.dx-tabpanel-tabs .dx-tabs-expanded .dx-tab:not(.dx-tab-selected):first-of-type {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-tabpanel>.dx-tabpanel-tabs .dx-tabs {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.promineo-tab-panel .dx-tabs {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

.dx-tabpanel>.dx-tabpanel-tabs .dx-tabs .dx-tab {
  color: transparent;
}

.promineo-tab-panel.dx-tabpanel .dx-multiview-wrapper {
  border: none;
}

.dx-tabpanel>.dx-tabpanel-tabs .dx-tab {
  padding: 5px 12px 4px 12px;
}