/* Text Box text */
.formula-tester .promineo-textbox:not(.size-large) input.dx-texteditor-input {
  font-size: 14px;
  margin-top: 0px;
}

/* Dropdown selected item text */
.formula-tester .promineo-selectbox .dx-dropdowneditor-input-wrapper.dx-selectbox-container .dx-texteditor-input-container .dx-texteditor-input {
  font-size: 14px;
  margin-top: 0px;
}

/* Dropdown item text */
.formula-tester.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  font-size: 14px; 
}

/* Dropdown placeholder text */
.formula-tester .promineo-selectbox .dx-dropdowneditor-input-wrapper.dx-selectbox-container .dx-texteditor-input-container .dx-placeholder {
  font-size: 14px;
  color: var(--color-light-text-gray);
}

/* Dropdown button */
.formula-tester .promineo-select-dropdown-button {
  margin-top: 11px;
}

/* Dropdown editor text for Date Picker */
.formula-tester .dx-dropdowneditor-input-wrapper .dx-texteditor-input {
  font-family: Inter, sans-serif;
  font-size: 14px;
  margin-top: 2px;
}

/* Dropdown button for DatePicker */
.formula-tester .promineo-datepicker .dx-dropdowneditor-button {
  margin-top: 8px;
}

