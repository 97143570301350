.promineo-tree-view-dropdown.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
}

.promineo-tree-view-dropdown.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
  border-color: transparent;
}

.promineo-tree-view-dropdown .dx-placeholder {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: var(--color-text-gray);
  margin-top: -3px;
  border-color: var(--color-ilap-blue);
}